
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, computed, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
// import BillManagementDetailVue from "../bill_management/Bill-ManagementDetail.vue";
export default defineComponent({
  components: {
    Datatable,
    // BillManagementDetailVue,
  },

  setup() {
    const isLoading = ref(true);
    const route = useRoute();
    const checkpopup = ref(true);
    const router = useRouter();
    const tableHeader = ref([
      {
        name: "No.",
        key: "no",
        sortable: false,
        align: "center",
      },
      {
        name: "Code",
        key: "transaction_partner_order_code",
        sortable: false,
        align: "center",
      },
      {
        name: "Type",
        key: "transaction_partner_type",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Description",
        key: "transaction_partner_description",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Date/Time",
        key: "create_at",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Credit",
        key: "transaction_partner_we_credit",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Detail",
        key: "actions",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
    ]);

    const searchConfig = computed(() => {
      return store.state.WalletModule.filterTransactionForm;
    });
    const bank = computed(() => {
      return store.state.WalletModule.backApprove;
    });
    const walletTransactionInvoice = computed(() => {
      return store.state.WalletModule.walletTransactionInvoice;
    });
    console.log(walletTransactionInvoice);
    const store = useStore();
    const walletList = computed(() => {
      return store.getters["WalletModule/getTransactionList"].filter((val) => {
        return (
          (val.transaction_partner_order_code != null &&
            val.transaction_partner_order_code
              .toLowerCase()
              .includes(searchConfig.value.search_text.toLowerCase())) ||
          val.transaction_partner_type
            .toLowerCase()
            .includes(searchConfig.value.search_text.toLowerCase()) ||
          val.transaction_partner_description
            .toLowerCase()
            .includes(searchConfig.value.search_text.toLowerCase())
        );
      });
    });

    async function toView(item) {
      checkpopup.value = false;
      if (item.transaction_partner_order_code == null) return;
      store.commit("setLoadingGlobal", true);
      const result = await store.dispatch(
        `WalletModule/${Actions.FETCH_WALLET_TRANSACTION_BY_ID}`,
        {
          transactionId: item.transaction_partner_province_id,
          orderCode: item.transaction_partner_order_code,
        }
      );
      store.commit("setLoadingGlobal", false);
      if (item.transaction_partner_type == "Invoice") {
        await store.dispatch(
          `WalletModule/${Actions.FETCH_WALLET_TRANSACTION_BY_INVOICE}`,
          {
            transactionIdinvoice: item.transaction_partner_order_code,
          }
        );
        router.push({
          path: `/management/wallet-transaction/${item.transaction_partner_province_id}/${item.transaction_partner_type}`,
        });
      } else {
        if (result == false) return;
        router.push({
          path: `/management/wallet-transaction/${item.transaction_partner_province_id}/${item.transaction_partner_order_code}`,
        });
      }
    }
    async function init() {
      isLoading.value = true;
      await store.dispatch(
        "WalletModule/" + Actions.FETCH_WALLET_TRANSACTION_LIST
      );
      await store.dispatch("WalletModule/" + Actions.FETCH_BANK_APPROVE);
      // walletList.value.push(bank.value);
      console.log(walletList.value);

      isLoading.value = false;
    }

    onMounted(() => {
      init();
    });
    return {
      moment,
      tableHeader,
      walletList,
      searchConfig,
      route,
      toView,
      isLoading,
      walletTransactionInvoice,
      checkpopup,
      bank,
    };
  },
});
